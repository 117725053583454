.textBockChecker {
    float: left;
    width: calc(100% - 32px) !important;
    max-width: calc(100% - 32px) !important;
}

.buttonBockChecker {
    float: left;
}

.m365NavItem {
    width: 100%;
    cursor: pointer;
    display: flex;
    height: 40px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
    .m365NavItem:hover {
        background-color: rgb(225, 223, 221);
    }

.m365NavIcon {
    float: left;
    font-size: 16px;
    margin-top: 0px;
    margin-right: 4px;
    margin-bottom: 0px;
    margin-left: 4px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    flex-shrink: 0;
    color: rgb(49, 100, 147);
    cursor: pointer;
    background-color: transparent;
}

.m365DragIcon {
    float: left;
    font-size: 16px;
    margin-top: 4px;
    margin-right: 10px;
    margin-bottom: 0px;
    margin-left: 4px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    flex-shrink: 0;
    color: rgb(49, 100, 147);
    cursor: pointer;
    background-color: transparent;
}


.m365SingleDragIcon {
    float: left;
    font-size: 16px;
    margin-top: 8px;
    margin-right: 10px;
    margin-bottom: 0px;
    margin-left: 4px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    flex-shrink: 0;
    color: rgb(49, 100, 147);
    cursor: pointer;
    background-color: transparent;
}

.m365NavLabel {
    float: left;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    display: block;
    cursor: pointer;
    margin-top: 0px;
    margin-right: 4px;
    margin-bottom: 0px;
    margin-left: 4px;
    background-color: transparent;
}

.m365NavDiv {
    height: 24px;
    left: 4px;
    border-radius: 4px;
    width: 4px;
    margin-top: 1px;
    margin-right: 4px;
    margin-bottom: 0px;
    margin-left: 4px;
    float: left;
}

.m365Wrap {
    display: flex;
    flex-direction: row;
    float:left;
    width:100%;
}

    .m365Wrap:before,
    .m365Wrap:after {
        content: "";
        flex: 1 1;
        border-bottom: 2px solid #00305e;
        margin: auto;
        margin-top: 15px;
    }

.m365WizardLabel {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    font-weight: 400;
    background-color: transparent;
}

.m365WizardIcon {
    font-size: 24px;
    height: 24px; 
    width: 24px;
    margin-left: 7px;
    margin-right: 7px;
}

.select {
    background-color: rgb(255, 255, 255);
}

.selected {
    background-color: #00305e;
}
